import 'bootstrap';
export function show(config){
	var defaultSettings = {
		message: '',
		color: 'primary',
		time: 4000
	};
	const toastId = generateToastId();
	var settings = {...defaultSettings, ...config}
	var template = `<div id="${toastId}" class="toast position-fixed align-items-center text-bg-${settings.color} border-0 toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
						<div class="d-flex">
							<div class="toast-body">
								${settings.message}
							</div>
							<button type="button" class="btn-close btn-close-white me-2 m-auto close-toast" data-bs-dismiss="toast" aria-label="Zapri" title="Zapri" data-toggle="tooltip"></button>
						</div>
					</div>`;
	$('body').append(template);
	$(document).on('click',`#${toastId} .close-toast`,function(e){
		e.preventDefault();
		$(`#${toastId}.toast`).remove();
	});
	var removeToast = setTimeout(function(){
		$(`#${toastId}.toast`).remove();
	},settings.time);
}

function generateToastId(length = 8) {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let toastId = 'toast-';
	for (let i = 0; i < length; i++) {
		toastId += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return toastId;
}