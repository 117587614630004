
//import bootbox from "bootbox";
//const bootbox = require('bootbox');

var backbtn = document.getElementById("backbtn");
backbtn && backbtn.addEventListener("click", function(){
	if (history.length){
		history.back();
		return false;
	}
});

function tooltip(){
	$('[data-toggle="tooltip"]').tooltip({ 'html': true });
	
	/* $("body").on('DOMNodeInserted', function(e) {
		if(e.target.classList.contains('toast')){
			$(e.target).find('[data-toggle="tooltip"]').tooltip();
		}
	}); */
}

function scrollBack(){
	if($('body.admin').length === 0){
		if(document.getElementById("vloga")){
			const selector = '.scroll.mainList';
			if (localStorage.getItem("scrollPosition") != null) {
				$(selector).scrollTop(localStorage.getItem("scrollPosition"));
			}
			
			$(selector).on("scroll", function() {
				localStorage.setItem("scrollPosition", $(selector).scrollTop());
				
			});
		}else{
			localStorage.removeItem("scrollPosition");
		}
	}
}


function autosize(textarea){
	textarea = textarea[0];
	if(!$(textarea).hasClass("dont-resize")){
		autosizeaction(textarea); // on load
		$(textarea).on('keyup focus paste change', function(e){
			autosizeaction(textarea); // on change
		});
	}
}

function autosizeaction(textarea){
	if(textarea.scrollHeight > textarea.offsetHeight){ // samo povečaj, ne zmanjšuj
		setTimeout(function(){
			$(textarea).css({'height':(textarea.scrollHeight) + 'px', 'overflow': 'hidden', 'min-height': 'unset', 'resize':'none' });
		},10);
	}
}

function textareaExpand() {
	if($('#vloga, #porocilo').length){
		$('textarea').each(function(){ // onload current textarea
			autosize($(this));
		});
		
		$(document).on('DOMNodeInserted', function(e) {
			const areas = $(e.target).find('textarea');
			if(areas.length > 0){
				areas.each(function(){
					autosize($(this)); // onload new textarea
				});
			}
		});
	}
}


function catchEnter(){
	if($('#vloga, #porocilo').length){
		$(document).ready(function() {
			$('input').keydown(function(event){
				if(event.keyCode == 13) {
					event.preventDefault();
					return false;
				}
			});
		});
	}
}

var getWordCount = function(v){
	var matches = v.match(/\S+/g) ;
	return matches?matches.length:0;
}
function triggerMaxWords(){
	$(document).on('change', 'input[class*="maxwords"], textarea[class*="maxwords"]', function(){
		var maxWords = 1;
		var classes = $(this).attr('class').split(/\s+/);
		$.each(classes, function(i,name) {
			if (name.indexOf('maxwords') === 0) {
				maxWords = parseInt(name.replace('maxwords',''));
			}
		});
		if(getWordCount($(this).val()) > maxWords){
			var words = $(this).val().split(' ');
			$(this).val(words.slice(0,maxWords).join(' '));
		}
	});
}

function changeLoadingPosition(){
	$('body').on('classChangeLoading', function() {
		let offsetTop = 0;
		
		const clientHeight = document.documentElement.clientHeight;
		const fromTop	= window.pageYOffset || document.documentElement.scrollTop;
		
		const heightLoadingHalf = 150 / 2;
		const opacityLoadingDiv = document.querySelector(".opacity-loading").getBoundingClientRect();
		const divX = opacityLoadingDiv.x;
		const divTop = opacityLoadingDiv.top;
		const divHeight = opacityLoadingDiv.height;
		
		if( divTop >= 0 && divTop+divHeight <= clientHeight ){ // cel div na ekranu
			offsetTop = divHeight/2 - heightLoadingHalf;
			
		}
		else if( divX + divHeight < fromTop + clientHeight ){ // spodnji rob na ekranu
			const onScreenHeight = divHeight - divTop;
			offsetTop = onScreenHeight / 2 - heightLoadingHalf;
		}
		else if( divTop >= 0 ){ // zgornji rob na ekranu
			offsetTop = divTop / 2 + heightLoadingHalf;
		}
		else{ // vmes div
			offsetTop = divTop + clientHeight/2 - heightLoadingHalf;
		}
		
		$("body").get(0).style.setProperty("--loadingTop", offsetTop+"px");
	});
}

function triggerAnchorClick(){
	if($('#vloga, #porocilo').length){
		$(document).on('click', '.note', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var elementID = $(this).data('load');
			var html = $('#'+elementID).html();
			if(typeof html !== 'undefined'){
				bootbox.alert(html);
			}
		})
	}
}

function vrednostProstovoljnoDelo(){
	if($('[id$="_vrsta_dela"]').length){
		$(document).on('change', '[id$="_vrsta_dela"]', function(e){
			const value = $(this).val();
			let output = null;
			switch (value) {
				case 'organizacijsko delo':
					output = 13;
					break;
				case 'vsebinsko delo':
					output = 10;
					break;
				case 'drugo prostovoljno delo':
					output = 6;
					break;
			}
			const inputVrednostUre = $(this).closest('tr').find('input[id$="_vrednost_ure"]');
			inputVrednostUre.val(output);
			$(inputVrednostUre).trigger("change");
		})
	}
}

$(function($){
	tooltip();
	scrollBack();
	textareaExpand();
	catchEnter();
	triggerMaxWords();
	changeLoadingPosition();
	triggerAnchorClick();
	vrednostProstovoljnoDelo();
});