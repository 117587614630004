
import * as fnc from "./functions.js";

const beforeUnloadListener = (event) => {
	event.preventDefault();
	return (event.returnValue = 'Ali ste prepričani, da želite končati urejanje?');
};

var unloadDefined = false;
function unloadListener(){
	$(' #porocilo input, #porocilo textarea, #porocilo select ').on('change', (event) => {
		if(!unloadDefined){
			addEventListener("beforeunload", beforeUnloadListener, {capture: true});
			unloadDefined = true;
		}
	});
}

function savePorocilo(){
	
	if ($('form#porocilo').hasClass("saveOsnutekPorocilo")) {
		
		var disabled = $('#porocilo input:disabled').removeAttr('disabled');
		const formData = fnc.getFormData($('form#porocilo'));
		
		if(!fnc.allKeysEmpty(formData)){
			var formDataJson = JSON.stringify(formData);
			disabled.attr('disabled','disabled');
			let recordID = $('#porocilo').data('reportid');
			let userId = $('#porocilo').data('userid');
			
			$.post('/evloge/porocilo/'+recordID, { 'data':formDataJson, 'userid':userId }, function(data){
				if(fnc.isJsonString(data)){
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						toasts.show({
							message:'Poročilo je bilo shranjeno kot osnutek.'
						});
						if(unloadDefined){
							removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
						}
						$(document).trigger('saved');
					}else{
						if(jsonData.useriderror){
							toasts.show({
								message:`NAPAKA<br>
											Prijavljen uporabnik se ne sklada s poročilom!<br>
											Zaradi varnosti podatkov poročilo ni bilo shranjeno!<br><br>
											Za odpravo napake se morate odjaviti in ponovno prijaviti.<br>
											Morebitne spremembe niso bile shranjene, zato si jih ročno shranite na vaš računalnik v beležnico!`,
								color: 'danger',
								time: 10000000
							});
						} else {
							toasts.show({
								message:'Napaka, poročilo ni bilo shranjeno kot osnutek.',
								color: 'danger'
							});
						}
					}
				}else{
					console.log(data);
					toasts.show({
						message:'Napaka, poročilo ni bilo shranjeno kot osnutek.',
						color: 'danger'
					});
				}
			})
		}
	}
}

function savePorociloClickTrigger(){
	if($('#savePorocilo').length){
		$('#savePorocilo').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			savePorocilo();
		})
	}
}

function obdobjeDateTo(){
	$(document).on('change', '#obdobje_date_to', function(e){
		const value = $(this).val();
		$('span.obdobje_date_to').each(function(){
			$(this).text(value);
		});
	});
}

function calculateRequiredFinance(){
	
	const selector = '#calculateRequiredFinance'
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const zaprosenaId = $(this).data('zaprosena');
			const zaprosenaVal = $(`#${zaprosenaId}`).val();
			const zaprosenaNum = fnc.parseNumber($(`#${zaprosenaId}`));
			
			const prejetaId = $(this).data('prejeta');
			const prejetaVal = $(`#${prejetaId}`).val();
			const prejetaNum = fnc.parseNumber($(`#${prejetaId}`));
			
			if(zaprosenaVal != '' && prejetaVal != ''){
				
				// equation
				const requiredFinance = prejetaNum + ( zaprosenaNum * ( prejetaNum / zaprosenaNum )); // TODO: preglej enacbo!!
				
				const text = `
					Prikaz porabe sredstev najmanj v višini:<br/>
					<b>${fnc.numberWithCommas(requiredFinance)}</b> EUR<br/>
					<div class="warning"><p>Izračunana vrednost je samo informative narave!</p></div>
				`;
				$('#requiredSum').html(text);
			}else{
				let toastText = '';
				if(zaprosenaVal == '' && prejetaVal == ''){
					toastText = 'Napaka, obe vrednosti (skupaj zaprosena in skupaj prejeta) sta prazni.';
				}
				else if(zaprosenaVal == ''){
					toastText = 'Napaka, vrednost skupaj zaprosena je prazna.';
				}else{
					toastText = 'Napaka, vrednost skupaj prejeta je prazna.';
				}
				toasts.show({
					message: toastText,
					color: 'danger'
				});
			}
		})
	}
	
}

$(function($){
	unloadListener();
	savePorociloClickTrigger();
	obdobjeDateTo();
	calculateRequiredFinance();
});