


function signnocert(){
	$('#oddajVlogo').on('click', function(e){
		window.open(this.dataset.file , "_blank");
	});
}

function oddajDopolnitevVloge(){
	$('#oddajDopolnitevVloge').on('click', function(e){
		window.open(this.dataset.file , "_blank");
	});
}


$(function($){
	
	signnocert();
	oddajDopolnitevVloge();
	
});