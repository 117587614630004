
function saveFileAdmin(){
	$(document).on('click', '.saveFileAdmin', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const fileId = this.dataset.fileid;
		const title = $(`#fileName_${fileId}`).val();
		
		$.get('/eva/editdata/files/'+fileId+'', { 'title': title }, function(data){
			
			if(data != 'error'){
				window.location.reload();
			}else{
				toasts.show({
					message:'Napaka!',
					color: 'danger'
				});
			}
		});
	});
}

function deleteFileAdmin(){
	$(document).on('click', '.deleteFileAdmin', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const fileId = this.dataset.fileid;
		if(confirm("Ali res želiš izbrisati datoteko?")){
			$.get('/eva/editdata/files/delete/'+fileId+'', { }, function(data){
				if(data != 'error'){
					window.location.reload();
				}else{
					toasts.show({
						message:'Napaka!',
						color: 'danger'
					});
				}
			});
		}
	});
}

function reorderFilesAdmin(){
	$(document).on('click', '#reorderFilesAdmin', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const vlogaId = this.dataset.vlogaid;
		let order = {};
		
		$('.fileOrder').each(function( index ) {
			order[this.dataset.fileid] = $(this).val();
		});
		$.get('/eva/editdata/files/reorder/'+vlogaId+'', { 'order': JSON.stringify(order) }, function( data ){
			if(data != 'error'){
				window.location.reload();
			}else{
				toasts.show({
					message:'Napaka!',
					color: 'danger'
				});
			}
		});
	});
}

const uploadFile = function(file, vlogaId) {
	
	const request = new XMLHttpRequest();
	request.upload.onload = () => {
		
	}
	request.upload.onerror = () => {
		toasts.show({
			message:'Napaka pri nalaganju datoteke',
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onabort = () => {
		toasts.show({
			message:'Nalaganje je bilo preklicano',
			color: 'danger',
			time: 10000
		});
	}
	request.onreadystatechange = () => {
		if (request.readyState === 4 && request.status === 200) {
			var jsonData = JSON.parse(request.responseText);
			
			if(!jsonData.error){
				window.location.reload();
				
			} else {
				toasts.show({
					message:jsonData.error,
					color: 'danger',
					time: 10000
				});
			}
		}
	};
	
	const API_ENDPOINT = "/eva/editdata/files/upload/"+vlogaId;
	request.open("POST", API_ENDPOINT, true);
	
	const formData = new FormData();
	formData.append("file", file);
	request.send(formData);
};

function defineUpload(){
	$(document).on("change", 'input[type="file"]#uploadAdmin', function(event){
		uploadFile(event.target.files[0], $(this).data('vlogaid'));
	});
}


function listKomisije(){
	$(document).on('click', '.addkomisija', function(e){
		e.preventDefault();
		var vlogaId = $(this).data('id');
		var list = $('#listkomisije option:selected');
		var name = list.text();
		var id = list.val();
		if(id != ''){
			$('.listkomisija ul').append(`<li><button type="button" class="btn btn-delete removekomisija" data-vloga="${vlogaId}"  title="Odstrani člana komisije" data-toggle="tooltip"></button> ${name}<input type="hidden" class="clanikomisije" name="clanikomisije[]" value="${id}"></li>`);
			list.attr('disabled', true);
			$('#listkomisije option[value=""]').attr('selected',true);
		}
	});
	$(document).on('click', '.removekomisija', function(){
		var parentLine = $(this).parent('li');
		var clanId = $(this).parent('li').find('input').val();
		parentLine.remove();
		$('#listkomisije option[value="'+clanId+'"]').attr('disabled', false);
	});
	$(document).on('click', '#savekomisija', function(){
		var vlogaId = $(this).data('vloga');
		var formData = [];
		$('.clanikomisije').each(function(){
			formData.push($(this).val());
		});
		$.post(`/eva/vloga/${vlogaId}/assign`, { 'clanikomisije[]' : formData }, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(!jsonData.error){
				window.location.reload();
			}
		});
	});
}

function zacniTockovanje(){
	const selector = '#zacniTockovanje';
	if( $(selector).length > 0 ){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalTockovanje [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			let vlogaID = $('#mainList').data('id');
			$.post(`/eva/vloga/${vlogaID}/startscoring`, {  }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(data.length > 5 && !jsonData.error){
					/*toasts.show({
						message:'Na vlogi je bil sprožen proces točkovanja.',
						time: 100000
					});*/
					window.location.reload();
				}else{
					toasts.show({
						message: jsonData.message ? jsonData.message : 'Na začetku procesa točkovanja je prišlo do težave!',
						color: 'danger'
					});
				}
				$('.loadingWrapper').removeClass('opacity-loading');
			})
			
		})
	}
}

function getFormDataAdmin($form){
	const data = new FormData($form[0]);
	var values = {};
	var cnt=0;
	for (var [key, value] of data.entries()) { 
		values[key] = value;
	}
	  
	//const values = Object.fromEntries(data.entries());
	return values;
}

function numbersWithPoint(values){
	let valuesNew = {};
	for (const variable in values){
		valuesNew[variable] = values[variable];
		if (valuesNew[variable].includes(',')) {
			valuesNew[variable] = valuesNew[variable].replace(/\./g, '').replace(/,/g, '.');
		}
	}
	return valuesNew;
}

function processFormData(selector, keyName) {
	const data = getFormDataAdmin($(selector));
	if (Object.keys(data).length !== 0) {
		sentToserver[keyName] = JSON.stringify(numbersWithPoint(data));
	}
}

function scoringPage(){
	
	var interVal = setInterval(function(){
		saveData();
	}, 60000); // save once per minute
	var sentToserver = {};
	function saveData(){
		if($('.scoring .scoringPage').length > 0){
			savePoints();
		}else if($('.odlocba .scoringPage').length > 0){
			saveOdlocbaData();
		}else if($('.pogodba .scoringPage').length > 0){
			savePogodbaData();
		}
	}
	
	const processFormData = (selector, key) => {
		const data = numbersWithPoint( getFormDataAdmin($(selector)));
		if (Object.keys(data).length > 0) {
			sentToserver[key] = JSON.stringify(data);
		}
	};
	
	function savePoints(){
	
		const recordID = [
			$('#values_data').data('dataid'),
			$('#values_skrbnik').data('dataid'),
			$('#values_komisija').data('dataid'),
		].find(Boolean); // finds first element that is valid (exists)
		
		processFormData('#values_data', 'values_data');
		processFormData('#values_skrbnik', 'skrbnik_data');
		processFormData('#values_komisija', 'komisija_data');
		
		/* const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const section = urlParams.get('section');
		
		var sectionParam = '';
		if(section != null && section != '' ){
			sectionParam = `?section=${section}`;
		}
		${sectionParam} */
		
		$.post(`/eva/vloga/${recordID}/savescoring`, sentToserver, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(data.length > 5 && !jsonData.error){
				/* toasts.show({
					message:'Točke so bile vmesno zabeležene.'
				}); */
			}else{
				toasts.show({
					message: 'Zaradi napake pri shranjevanju točk te niso bile shranjene.',
					color: 'danger'
				});
			}
		})
	}
	
	function saveOdlocbaData(){
	
		const recordID = $('#odlocba_data').data('dataid');
		
		processFormData('#odlocba_data_common', 'odlocba_data_common'); // za vse odlocbe skupno -> gre v tabelo vloga
		processFormData('#odlocba_data_vloga', 'odlocba_data_vloga'); // za to odlocbo skupno -> gre v tabelo vloga_data
		processFormData('#odlocba_data', 'odlocba_data'); // za vsak program te odlocbe -> gre v tabelo calculation ali v vloga_data -> odvisno če obstaja calculation
		
		$.post(`/eva/vloga/${recordID}/saveodlocbadata`, sentToserver, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(data.length > 5 && !jsonData.error){
				toasts.show({
					message:'Odločba je bila vmesno shranjena.'
				});
				//console.log('Odločba je bila vmesno shranjena.');
			}else{
				toasts.show({
					message: 'Zaradi napake pri shranjevanju odločbe ni bila shranjena.',
					color: 'danger'
				});
			}
		})
	}
	
	function savePogodbaData(){
	
		const recordID = $('#pogodba_data').data('dataid');
		
		processFormData('#pogodba_data', 'pogodba_data');
		
		$.post(`/eva/vloga/${recordID}/savepogodbadata`, sentToserver, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(data.length > 5 && !jsonData.error){
				toasts.show({
					message:'Pogodba je bila shranjena.'
				});
				//console.log('Pogodba je bila shranjena.');
			}else{
				toasts.show({
					message: 'Zaradi napake pri shranjevanju pogodba ni bila shranjena.',
					color: 'danger'
				});
			}
		})
	}
	
	function detectInputed( parent ){
		/* if($(parent).hasClass('odlocba')){
			return true;
		} */
		
		var allFilled = true;
		var inputFields = $(parent).find('input:visible, select:visible, textarea:visible, [id$="potrdiInterpret"]');
		for (var i = 0; i < inputFields.length; i++) {
			if (( inputFields[i].value === "" && !$(inputFields[i]).hasClass('notrequired') ) || $(inputFields[i]).hasClass('is-invalid') ){
				allFilled = false;
			}
		}
		return allFilled;
	}
	
	var getWordCount = function(v){
		var matches = v.match(/\S+/g) ;
		return matches?matches.length:0;
	}
	function maxNumberOfChars( textarea ){
		let tooMuch = false;
		if($(textarea).data("maxwords")){
			const maxWords = $(textarea).data("maxwords");
			if(getWordCount($(textarea).val()) > maxWords){
				tooMuch = true;
			}
		}
		if($(textarea).data("maxchars")){
			const maxchars = $(textarea).data("maxchars");
			if($(textarea).val().length > maxchars){
				tooMuch = true;
			}
		}
		return tooMuch;
	}
	
	function enableNextButton(currentPage, parent, focus = false){
		const lastPage = $('.scoringPage').last().data('page');
		if(currentPage === lastPage){
			$('#finishScoring').removeAttr('disabled');
			$('#finishKomentarOdlocba').removeAttr('disabled');
			$('#knjiziPogodbaModal').removeAttr('disabled');
		}else{
			parent.find('.next').removeAttr('disabled');
			if(focus){
				parent.find('.next').focus();
			}
		}
	}
	function disableNextButton(currentPage, parent){
		const lastPage = $('.scoringPage').last().data('page');
		if(currentPage === lastPage){
			$('#finishScoring').prop( "disabled", true );
		}else{
			parent.find('.next').prop( "disabled", true );
		}
	}
	
	const parent = $('.scoringPage:not(".hiddenPage")');
	if(detectInputed(parent)){
		enableNextButton($(parent).data('page'), parent);
	}
	
	$('.scoringPage .form-control').on("change keyup paste click", function() {
		const parent = $(this).parents('.scoringPage');
		
		let wrongInput = false;
		let text = '';
		
		// detect original value
		var orgVal = $(this).data('orgval');
		var newVal = $(this).val();
		$(this).tooltip('dispose');
		if(orgVal != newVal  && orgVal != '' ){
			$(this).removeClass('originalValue');
			$(this).addClass('changedValue');
			$(this).attr('title', 'Vrednost je bila spremenjena.');
			$(this).tooltip();
		}else{
			$(this).addClass('originalValue');
			$(this).removeClass('changedValue');
			$(this).attr('title', '');
		}
		
		if($(this).is("input") && !$(this).hasClass("text")){
			let value = $(this).val();
			if( value !== '' ){
				if($(this).attr('data-float') == 'true'){
					value = value.replace(/[^0-9.,]*/g, ''); // leave only numbers!
					//value = parseFloat(value);
				}else{
					value = value.replace(/[^0-9]*/g, ''); // leave only numbers!
					value = parseInt(value);
				}
				$(this).val( isNaN(value) ? '' : value );
			}
			if($(this).attr('data-scoremax')){
				const max = parseInt($(this).data('scoremax'));
				if(value > max){
					wrongInput = true;
					text = `Vnesena vrednost ne sme biti večja od ${max}!`;
				}
			}
			if($(this).attr('data-scoremin')){
				const min = parseInt($(this).data('scoremin'));
				if(value < min){
					wrongInput = true;
					text = `Vnesena vrednost ne sme biti manjša od ${min}!`;
				}
			}
		}
		if($(this).is("textarea")){
			if(maxNumberOfChars($(this))){
				wrongInput = true;
				
				if($(this).data("maxwords")){
					const maxWords =  $(this).data("maxwords");
					text = `Vnosno polje je omejeno na ${maxWords} besed! Skrajšajte vaše besedilo.`;
				}
				if($(this).data("maxchars")){
					const maxchars = $(this).data("maxchars");
					text = `Vnosno polje je omejeno na ${maxchars} znakov! Skrajšajte vaše besedilo.`;
				}
			}
		}
		
		$(this).removeClass('is-invalid');
		$(this).siblings(`.invalid-feedback`).remove();
		
		if(wrongInput){
			$(this).addClass('is-invalid');
			$(this).after(`<div class="invalid-feedback">${text}</div>`);
			disableNextButton($(parent).data('page'), parent);
		}else{
			if(detectInputed( parent )){
				enableNextButton($(parent).data('page'), parent);
			}else{
				disableNextButton($(parent).data('page'), parent);
			}
		}
	});
	
	$('.scoringPage button.potrdiInterpret').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const dataId = $(this).data('id');
		const parent = $(this).parents('.scoringPage');
		
		const select = parent.find('select');
		let value = '';
		if(select){
			value = select.val();
			const idInput = select.attr('id');
			$(`[id="${idInput}_input"]`).val(value);  // because of dot in id, it is necessary to use attr selector
		}else{
			parent.find('input').attr('readonly', true);
			value = parent.find('input').val();
		}
		
		$(select).removeClass('is-invalid');
		$(select).siblings(`.invalid-feedback`).remove();
		
		if( value != '' ){
			$(this).attr('disabled', true);
			$(this).text('POTRJENO');
			$(`[id="${dataId}"]`).val('true'); // because of dot in id, it is necessary to use attr selector
			
			enableNextButton($(parent).data('page'), parent, true);
			saveData();
		}else{
			const text = 'Polje je prazno!'
			$(select).addClass('is-invalid');
			$(select).after(`<div class="invalid-feedback">${text}</div>`);
			disableNextButton($(parent).data('page'), parent);
		}
	});
	
	$('.scoringPage select.selectPotrdi').on('change',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const idInput = $(this).attr('id');
		$(`[id="${idInput}_potrdiInterpret"]`).val('');
		$(`[id="${idInput}_input"]`).val($(this).val());
		
		const parent = $(this).parents('.scoringPage');
		
		const button = $(parent).find("button.potrdiInterpret");
		$(button).attr('disabled', false);
		$(button).text('POTRDI');
		
		disableNextButton($(parent).data('page'), parent);
	});
	
	$('.buttonsScoring button.next').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const parent = $(this).parents('.scoringPage');
		
		if(detectInputed( parent )){
			
			saveData();
			
			parent.addClass('hiddenPage');
			
			const page = parent.data('page');
			const nextPage = page+1;
			
			const nextPageElement = $(`.scoringPage.page${nextPage}`);
			nextPageElement.removeClass('hiddenPage');
			
			if(detectInputed(nextPageElement)){
				enableNextButton(nextPage, nextPageElement);
			}
			
			const elementToFocus = $(nextPageElement).find('input,textarea,select').filter(':visible:first');
			$(elementToFocus).focus(); // SET FOCUSED first element
			
			scrollToSection(nextPageElement);
		}else{
			toasts.show({
				message:'Ni mogoče nadaljevati, saj niste ustrezno izpolnilnili vnosnih polj!',
				color: 'danger'
			});
		}
	});
	
	
	var currentSection = null;
	function scrollToSection(pageElement){
		const idElement = pageElement.data('scroll');
		if( idElement !== currentSection){
			if( idElement != '' && idElement != null && $(`#${idElement}`).length > 0 ){
				$('html, body').animate({
					scrollTop: $(`#${idElement}`).offset().top-(window.innerHeight/2)
				}, 500);
			}
			currentSection = idElement;
		}
	}
	scrollToSection($(`.scoringPage.page0`));
	
	
	$('.buttonsScoring button.prev').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const parent = $(this).parents('.scoringPage');
		parent.addClass('hiddenPage');
		
		const page = parent.data('page');
		const nextPage = page-1;
		
		const nextPageElement = $(`.scoringPage.page${nextPage}`);
		nextPageElement.removeClass('hiddenPage');
		scrollToSection(nextPageElement);
	});
	
	$('#finishScoringAfterModal').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		saveData();
		
		const idVloge = [
			$('#values_data').data('id'),
			$('#values_skrbnik').data('id'),
			$('#values_komisija').data('id'),
		].find(Boolean);
		const dataId = [
			$('#values_data').data('dataid'),
			$('#values_skrbnik').data('dataid'),
			$('#values_komisija').data('dataid'),
		].find(Boolean);
		
		const iteration = $('#values_komisija').data('iteration');
		
		$.post(`/eva/vloga/${dataId}/finishscoring`, { 'iteration': iteration }, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(jsonData.error == false){
				window.location.href = `/eva/vloga/${idVloge}`;
			} else {
				toasts.show({
					message:'Prišlo je do napake pri zaključevanju točkovanja!',
					color: 'danger'
				});
			}
		})
	});
	
	$('#finishKomentarOdlocba').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		saveData();
		
		const dataid = $(this).data('dataid');
		window.open(`/eva/vloga/${dataid}/showodlocba`, '_blank').focus();
	});
	
	$('#shraniData').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		saveData();
	});
	
	$('#shraniDataPogodba').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		saveData();
	});
	
	$('#knjiziPogodbaModal').on('click',function(e){
		saveData();
	});
	
	$('#knjiziPogodba').on('click',function(e){
		
		$("#modalKnjiziVOdos [data-bs-dismiss=modal]").trigger('click');
		$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
		
	});
	
	$('#reloadPdf').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		saveData();
		reloadPDF();
	});
	
	function reloadPDF(){
		$('object').each(function( index, el ){
			$(el).attr('data', $(el).attr('data'));
			delectLoadedEmbed();
		});
	}
	
	document.addEventListener('keydown', e => { // CTRL + S
		if (e.ctrlKey && e.key === 's') {
			e.preventDefault();
			e.stopPropagation();
			if($('.fields.pogodba')){
				saveData();
				reloadPDF();
			}
		}
	});
	
}

function commissionFinish(){
	
	$('#commissionFinishModal').on('click',function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const vlogaId = $(this).data('id');
		const userId = $(this).data('userid');
		const iteration = $(this).data('iteration');
		
		$.post(` /eva/vloga/${vlogaId}/commissionfinish/${userId}`, { 'iteration':iteration }, function(data){
			if(!fnc.isJsonString(data)){
				console.log(data);
			}
			var jsonData = JSON.parse(data);
			if(!jsonData.error){
				window.location.href = `/eva`;
			}else{
				toasts.show({
					message: 'Zaradi napake točkovanje ni bilo zaključeno.',
					color: 'danger'
				});
			}
		});
	});
	
}

function closeSupplButton(){
	if( $('#submissions').length > 0 ){
		$('.closeSupplButton').on('click', function(e){
			var vlogaId = $(this).data( 'dataid' );
			$("#endSupplButton").attr( "data-dataid", vlogaId );
		});
		
		$('#endSupplButton').on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const recordID = $(this).data('dataid');
			
			$.post(`/eva/reject/${recordID}`, { 'rejectType': 'sklep' }, function(data){
				var jsonData = JSON.parse(data);
				if(data.length > 5 && !jsonData.error){
					window.location.reload();
				}else{
					toasts.show({
						message:'Pri označevanju vloge kot zavrnjene s sklepom je prišlo do težave!',
						color: 'danger'
					});
				}
			});
		});
	}
}


function saveKomisijaPopravki(){
	
	const selector = '#saveKomisijaPopravki';
	if( $(selector).length > 0 && $(selector).attr('disabled') === undefined ){
		
		$(selector).on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			saveVrstice();
		});
		
		var saveVrsticeAutomatic = setInterval(function(){
			saveVrstice();
		}, 60000); // save once per minute
		
		function validateNumbers(element){
			const value = $(element).val();
			let inputOK = true;
			let text = '';
			
			if($(element).attr('max')){
				const max = parseInt($(element).attr('max'));
				if(value > max){
					inputOK = false;
					text = `Vnesena vrednost ne sme biti večja od ${max}!`;
				}
			}
			if($(element).attr('min')){
				const min = parseInt($(element).attr('min'));
				if(value < min){
					inputOK = false;
					text = `Vnesena vrednost ne sme biti manjša od ${min}!`;
				}
			}
			$(element).removeClass('is-invalid');
			$(element).siblings(`.invalid-feedback`).remove();
			
			if(!inputOK){
				$(element).addClass('is-invalid');
				$(element).after(`<div class="invalid-feedback">${text}</div>`);
			}
			return inputOK;
		}
		
		$('.rowInputsCommissionEdit input, .rowInputsCommissionEdit select').on('change paste keyup',function(e){ // calculate SUM on the fly
			const inputs = $(this).parent().parent().find('.input input, .input select');
			let sum = 0;
			let sum1 = 0;
			let sum2 = 0;
			inputs.each(function( index ) {
				
				let value = $(this).val();
				value = value.replace(/[^0-9]*/g, ''); // leave only numbers!
				value = parseInt(value);
				$(this).val(value);
				
				if($(this).data('sum2')){
					sum2 = sum2 + value;
				}else{
					sum1 = sum1 + value;
				}
				sum = sum + value;
			});
			
			const sumTd1 = $(this).parent().siblings('.rowSum1');
			$(sumTd1).text(sum1);
			const sumTd2 = $(this).parent().siblings('.rowSum2');
			$(sumTd2).text(sum2);
			
			const sumTd = $(this).parent().siblings('.rowSum');
			$(sumTd).text(sum);
			
			validateNumbers(this);
			
		});
		
		function saveVrstice(){
			const vrstice = $('#scores tr.vloga');
			let validated = true;
			// VALIDATE inputs
			vrstice.each(function( index ) {
				const inputs = $(this).find('input');
				inputs.each(function( index ) {
					validated = validated && validateNumbers(this);
				})
			});
			
			if(!validated){
				toasts.show({
					message: 'Vrednosti niso bile shranjene zaradi napak v vnosnih poljih!',
					color: 'danger'
				});
			}else{
				saveNumbers(vrstice);
			}
		}
		
		function saveNumbers(vrstice){
			let saved = true;
			let savedCount = 0;
			vrstice.each(function( index ) {
				
				const scoreId = $(this).data('scoreid');
				const year = $('select#year').val();
				//const section = $('#section').val();
				
				// shrani po posamezni vrstici
				const inputs = $(this).find('input, select');
				let vnosi = {};
				
				inputs.each(function( index ) {
					vnosi[$(this).attr('name')] = $(this).val();
				})
				
				$.post(` /eva/save/score/adjustment/${scoreId}`, { 'year':year, /* 'section':section, */ 'komisija_data': JSON.stringify(vnosi) }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(!jsonData.error){
						savedCount++;
					}else{
						saved = false;
						toasts.show({
							message: 'Zaradi napake pri shranjevanju točk te niso bile shranjene.',
							color: 'danger'
						});
					}
				});
			});
			if(saved && savedCount > 0){
				toasts.show({
					message: 'Vrednosti so bile uspešno shranjene.',
					color: 'success'
				});
			}
		}
		
	}
	
	$( "th a.noOrder" ).click(function( e ) {
		e.stopPropagation();
	});
}

function zakljuciTockovanjeFinal(){
	const selector = '#zakljuciTockovanjeFinal';
	if( $(selector).length > 0 ){
		
		$(selector).on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalTockovanje [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			const idVloge = $(this).data('id');
			
			$.post(`/eva/vloga/${idVloge}/startustvariodlocbe`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					window.location.href = `/eva/vloga/${idVloge}`;
				} else {
					$('.loadingWrapper').removeClass('opacity-loading');
					toasts.show({
						message:'Prišlo je do napake pri zaključevanju točkovanja!',
						color: 'danger'
					});
				}
			})
		});
	}
}


function startUstvariPogodbe(){
	const selector = '#startPogodbe';
	if( $(selector).length > 0 ){
		$(selector).on('click',function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$("#modalStartPogodbe [data-bs-dismiss=modal]").trigger('click');
			$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
			
			const idVloge = $(this).data('id');
			
			$.post(`/eva/vloga/${idVloge}/startustvaripogodbe`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					window.location.href = `/eva/vloga/${idVloge}`;
				} else {
					$('.loadingWrapper').removeClass('opacity-loading');
					toasts.show({
						message:'Prišlo je do napake!',
						color: 'danger'
					});
				}
			})
		});
	}
	
}

var embedElement = null;
if(document.querySelector('object')){
	var embedElement = document.querySelector('object');
	if(embedElement){
		delectLoadedEmbed();
	}
}
function delectLoadedEmbed(){
	$('.embedPdf .loading-inside').show();
	embedElement.addEventListener('load', function(){
		$('.embedPdf .loading-inside').hide();
	});
}

function startPorocanje(){
	const selector = '#editporocanjeButton';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const parent = $(this).parent().parent();
			
			const yearPorocanje = $(parent).find('#yearPorocanje').val();
			const datePorocanje1 = $(parent).find('#datePorocanje1').val();
			const datePorocanje2 = $(parent).find('#datePorocanje2').val();
			
			const closeYear = $(parent).find('#closeYearPorocanje').val();
			
			if( datePorocanje1 != '' && datePorocanje2 != '' ){
				
				const oDatePorocanje1 = fnc.parseDateWithTime(datePorocanje1)
				const oDatePorocanje2 = fnc.parseDateWithTime(datePorocanje2)
				
				if(oDatePorocanje1 >= oDatePorocanje2){
					toasts.show({
						message: 'Datum oddaje končnega poročila je pred datumom oddaje delnega poročila.',
						color: 'danger'
					});
				}else{
					$("#modalStartPorocanje [data-bs-dismiss=modal]").trigger('click');
					$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
					
					let parameters = {
						'datePorocanje1': datePorocanje1,
						'datePorocanje2': datePorocanje2,
						'yearPorocanje': yearPorocanje,
					}
					
					if(closeYear){
						parameters['closeYear'] = closeYear;
					}
					
					let vlogaID = $('#mainList').data('id');
					$.post(`/eva/editporocanje/${vlogaID}`, parameters, function(data){
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							toasts.show({
								message: `Prvi del poročanja bo odprt do: ${datePorocanje1}<br/> Drugi del poročanja bo odprt do: ${datePorocanje2}`,
								time: 100000
							});
						}else{
							toasts.show({
								message: jsonData.message ? jsonData.message : 'Pri odpiranju poročanja je prišlo do težave!',
								color: 'danger'
							});
						}
						$('.loadingWrapper').removeClass('opacity-loading');
						window.location.reload();
					})
				}
			}else{
				toasts.show({
					message: 'Vnesi oba datuma zaprtja poročanja!',
					color: 'danger'
				});
			}
		})
	}
}

function delay(callback, ms) {
	var timer = 0;
	return function() {
	  	var context = this, args = arguments;
		clearTimeout(timer);
	  	timer = setTimeout(function () {
			callback.apply(context, args);
	  	}, ms || 0);
	};
}

function tenderSearch(){
	$('#search').keyup(delay(function (e) {
		if($(this).val().length > 5){
			$.get('/eva/odos/search/', {'q':$(this).val()}, function(data){
				$('#searchResults').html('');
				var jsonData = JSON.parse(data);
				jsonData.forEach((tender) => {
					var SOURCETARGETID = (tender.SOURCETARGETID.length) ? tender.SOURCETARGETID : tender.KLASIFIKACIJAZADEVE
					$('#searchResults').append(`
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">${SOURCETARGETID} - ${tender.DATUMVLOGE}</div>
								${tender.ZADEVAOPIS}
								<div class="col-12">
									<button type="button" class="btn btn-primary applytender" data-tender='{"odos_sourcetarget_id":"${SOURCETARGETID}","odos_tenderid":"${tender.ID}","odos_tender_description":"${tender.ZADEVAOPIS}","odos_tender_date":"${tender.DATUMVLOGE}", "odos_tender_clasification":"${tender.KLASIFIKACIJA}"}' data-bs-dismiss="modal" data-bs-target="#tenderSearch">Prenesi podatke</button>
								</div>
							</div>
							<span class="badge text-bg-light">ID: ${tender.ID}</span>
						</li>
					`);
				});
			})
		}
	}, 500));
	$(document).on('click','.applytender',function(e){
		e.preventDefault();
		var tenderData = $(this).data('tender');
		console.log(tenderData);
		for (const [key, value] of Object.entries(tenderData)) {
			console.log(value, key);
			$('#'+key).val(value);
		}
		$('#tenderSearch').hide();
	});
}

function tenderSearchParams(){
	$('#searchnow').on('click', function(e){
		e.preventDefault();
		$.get('/eva/odos/search/extended/', {
				'klasifikacija':$('#klasifikacija').val(),
				'leto': $('#year').val(),
				'vlagateljid': $('#vlagateljid').val(),
				'vlogaid': $('#vlogaid').val(),
				'klasifikacijazadeve': $('#klasifikacijazadeve').val(),
		}, function(data){
			$('#searchResults').html('');
			var jsonData = JSON.parse(data);
			jsonData.forEach((tender) => {
				$('#searchResults').append(`
					<li class="list-group-item">
    					<input class="form-check-input me-1" type="radio" name="caseId" value="${tender.ID}" id="caseId${tender.ID}">
    					<label class="form-check-label" for="caseId${tender.ID}">${tender.ZADEVAOPIS}<br>${tender.KLASIFIKACIJAZADEVE} - ${tender.DATUMVLOGE}</label>
  					</li>
				`);
			});
		})
	});
	$('#savedata').on('click', function(e){
		var caseId = $('input[name="caseId"]:checked').val();
		var userId = $('#userid').val();
		var vlogaId = $('#vlogaid').val();
		$.post('/eva/odos/set/caseid/', {
			'caseId':caseId,
			'userId':userId,
			'vlogaId':vlogaId
		}, function(data){
			var jsonData = JSON.parse(data);
			if(jsonData.error == true){
				alert("Prišlo je do napake pri nastavljanju!");
			} else {
				window.location.reload();
			}
		})
	});
}

function saveDataRazpis(){
	if($('#saveDataRazpis').length){
		$('#saveDataRazpis').on('click', function(e){
			document.getElementById("adminRazpis").submit();
		});
		document.addEventListener('keydown', e => { // CTRL + S
			if (e.ctrlKey && e.key === 's') {
				e.preventDefault();
				e.stopPropagation();
				document.getElementById("adminRazpis").submit();
			}
		});
	}
}

function saveReportComments(){
	var disabled = $('form#porocilo input:disabled').removeAttr('disabled');
	const formData = fnc.getFormData($('form#porocilo'));
	
	var formDataJson = JSON.stringify(formData);
	disabled.attr('disabled','disabled');
	let recordID = $('form#porocilo').data('reportid');
	
	$.post(`/eva/report/${recordID}/savecomments`, { 'data':formDataJson }, function(data){
		if(!fnc.isJsonString(data)){
			console.log(data);
		}
		
		var jsonData = JSON.parse(data);
		if(data.length > 5 && !jsonData.error){
			toasts.show({
				message:'Komentarji so bili shranjeni kot osnutek.'
			});
		}else{
			toasts.show({
				message:'Pri shranjevanju komentarjev je prišlo do napake!',
				color: 'danger'
			});
		}
	})
}

function saveReportCommentsTrigger(){
	const selector = '#saveCommentsReport';
	if($(selector).length){
		
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			saveReportComments();
		})
		
		const interValSaveComments = setInterval(function(){
			$(selector).trigger('click');
		},60000);
	}
}

function reportSupplemet(){
	const selector = '#report_supplementation';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('form#porocilo'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (!isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('form#porocilo').data('id');
				let recordID = $('form#porocilo').data('reportid');
				
				$.post(`/eva/report/${recordID}/supplement`, { }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					
					let error = false;
					if(fnc.isJsonString(data)){
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							
							window.location.href = `/eva/vloga/${vlogaID}`;
							$('.loadingWrapper').removeClass('opacity-loading');
							
						} else {
							error = true;
						}
					} else {
						error = true;
					}
					if(error){
						$('.loadingWrapper').removeClass('opacity-loading');
						var text = 'Pri oddajanu vloge v dopolnjevanje je prišlo do težave!';
						if(jsonData.text){
							text = jsonData.text;
						}
						toasts.show({
							message: text,
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Ni komentarjev!<br>Če želite poslati to poročilo v dopolnjevanje, morate vnesti komentar, ki se nato vpiše v poziv na dopolnitve.',
					color: 'danger',
					time: 30000
				});
				$(`#modalDopolnitev [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
}

function reportConfirm(){
	const selector = '#report_confirm';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('form#porocilo'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('form#porocilo').data('id');
				let recordID = $('form#porocilo').data('reportid');
				
				$.post(`/eva/report/${recordID}/confirm`, { }, function(data){
					if(!fnc.isJsonString(data)){
						console.log(data);
					}
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri potrjevanju poročila je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo poročilo potrjeno.<br>Če želite potrditi to poročilo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				
				$("#modalPotrdi [data-bs-dismiss=modal]").trigger('click');
			}
		})
	}
}

function finishTender(){
	const selector = '#finishTender';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const vlogaId = $(this).data('vloga');
			
			$.post(`/eva/vloga/${vlogaId}/finishvloga`, { }, function(data){
				if(!fnc.isJsonString(data)){
					console.log(data);
				}
				var jsonData = JSON.parse(data);
				if(jsonData.error == false){
					window.location.href = `/eva`;
				} else {
					toasts.show({
						message:'Prišlo je do napake pri zaključevanju vloge!',
						color: 'danger'
					});
				}
			})
			
		})
	}
}


$(function($){
	
	zacniTockovanje();
	if($('.scoringPage').length > 0){
		scoringPage();
	}
	
	listKomisije();
	commissionFinish();
	saveKomisijaPopravki();
	zakljuciTockovanjeFinal();
	startUstvariPogodbe();
	
	closeSupplButton();
	
	startPorocanje();
	
	
	defineUpload();
	//saveFileAdmin();
	deleteFileAdmin();
	reorderFilesAdmin();
	
	saveDataRazpis();
	
	// ODOS
	tenderSearch();
	tenderSearchParams();
	
	// porocilo
	saveReportCommentsTrigger();
	reportSupplemet();
	reportConfirm();
	
	finishTender();
});