function isCapsLockOn(event) {
	return event.getModifierState("CapsLock");
}

function detectCapsLock(){
	const selector = 'form.capsLock';
	if($(selector).length){
		document.addEventListener("keydown", function(event) {
			const warningText = `<div class="warning"><p> Pozor, CapsLock je aktiviran! </p></div>`;
			$('.warningWrapper').html( isCapsLockOn(event) ? warningText : '' );
		});
	}
}

let passwordTimeout = null;
function togglePassword(){
	$(".toggle-password").on('click', function() {
		showHidePassword(this);
	});
	
	function showHidePassword(element){
		$(element).toggleClass("bi-eye");
		$(element).toggleClass("bi-eye-slash");
		
		var input = $($(element).attr("toggle"));
		if (input.attr("type") == "password") {
			input.attr("type", "text");
			toasts.show({
				message:"Geslo bo vidno 2 sekundi.",
				time: 2000
			});
			passwordTimeout = setTimeout(function(){
				showHidePassword(element)
			}, 2000)
		} else {
			clearTimeout(passwordTimeout);
			input.attr("type", "password");
		}
	}
}

function disablePaste(){
	const password2 = document.getElementById('password2');
	if(password2 != null){
		password2.onpaste = e => e.preventDefault();
	}
}

$(function($){
	if ($('.login-right').length > 0) {
		togglePassword();
		detectCapsLock();
		disablePaste();
	}
});