const uploadFile = function(file, fieldName, inputParent) {
	
	const inputFile = $(inputParent).find(`input[type="file"]`);
	const recordId = $(inputFile).data('recordid');
	const userId = $(inputFile).data('userid');
	
	const API_ENDPOINT = "/evloge/vloga/"+recordId+"/upload?fieldname="+fieldName;
	const request = new XMLHttpRequest();
	const formData = new FormData();
	formData.append("file", file);
	
	var supplement = $('form#vloga,form#porocilo').data('supplement');
	supplement = supplement === undefined ? false : supplement;
	
	request.upload.onload = () => {
		toasts.show({
			message:'Datoteka je bila uspešno naložena',
			color: 'success',
			time: 10000
		});
		/*var progressTimeout = setTimeout(function(){
			$('#progressbar').hide();
			$('#progressbar .bar').css('width', '0%');
		}, 1000)*/
	}
	request.upload.onerror = () => {
		toasts.show({
			message:'Napaka pri nalaganju datoteke',
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onabort = () => {
		toasts.show({
			message:'Nalaganje je bilo preklicano',
			color: 'danger',
			time: 10000
		});
	}
	/*request.upload.onprogress = event => {
		$('#progressbar').show();
		if (event.lengthComputable) {
			var percentComplete = (event.loaded / event.total) * 100;
			$('#progressbar .bar').css('width', percentComplete+'%');
		}
	}*/
	request.onreadystatechange = () => {
		if (request.readyState === 4 && request.status === 200) {
			if(!fnc.isJsonString(request.responseText)){
				console.log(request.responseText);
			}
			
			var jsonData = JSON.parse(request.responseText);
			if(!jsonData.error){
				const numParents = inputParent.find(`input[type="hidden"].${jsonData.fieldname}`).length;
				const fileSize = bytesToMB(file.size);
				const sumFiles = $(inputParent).find('.sumFiles');
				
				const newFileElement = $(`<div class="fileuploaded">
						<input type="hidden" class="${jsonData.fieldname}" name="${jsonData.fieldname}[${numParents}]" value="${jsonData.filename}">
						<input type="hidden" class="${jsonData.fieldname}FileSize" name="filesize_${jsonData.fieldname}[${numParents}]" value="${fileSize}">
						<input type="hidden" class="${jsonData.fieldname}Supplement" name="supplement_${jsonData.fieldname}[${numParents}]" value="${supplement}">
						
						<span class="deletefile" data-file="${jsonData.filename}" title="Odstrani datoteko ${jsonData.filename}">
							<img src="/assets/images/delete.svg" alt="Ikona odstrani datoteko" >
						</span>
						<a href="/uploads/${userId}/${recordId}/${jsonData.filename}" target="_blank">
							${jsonData.filename}
						</a>
						<span class="size">(${fnc.numberWithCommas(fileSize)} MB)</span>
					</div>`);
				newFileElement.insertBefore(sumFiles);
				
				const sizeSumSpan = $(sumFiles).find('.size');
				var fileSizeSum = 0;
				$(inputParent).find('.fileuploaded').each(function( index ) {
					const item = $( this ).find(`input[type="hidden"].${jsonData.fieldname}FileSize`);
					fileSizeSum = fileSizeSum + parseFloat(item.val());
				});
				fileSizeSum = parseFloat(fileSizeSum.toFixed(2));
				sizeSumSpan.html(`${fnc.numberWithCommas(fileSizeSum)} MB`);
				
				$('#save').trigger('click');
				$('#savePorocilo').trigger('click');
				
			} else {
				toasts.show({
					message:jsonData.error,
					color: 'danger',
					time: 10000
				});
			}
		}
	};
	request.open("POST", API_ENDPOINT, true);
	request.send(formData);
};

function bytesToMB(bytes) {
	const transformed = (bytes / (1024 * 1024));
	return parseFloat(transformed.toFixed(2));
}

function defineUpload(){
	$(document).on("change", 'input[type="file"]', function(event){
		const inputParent = $(this).closest('.files_wrapper');
		const files = event.target.files;
		const fieldName = event.target.dataset.name;
		const limitMB = 5;
		
		if(files[0].size < limitMB * 1000000){
			uploadFile(files[0], fieldName, inputParent);
		} else {
			toasts.show({
				message:`Datoteka je prevelika, velikost je omejena na ${limitMB} MB!`,
				color: 'danger',
				time: 100000
			});
		}
	});
}

var thisItem = null;
function defineDeleteFile(){
	$(document).on('click', '.deletefile', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const filename = $(this).data('file');
		thisItem = this;
		
		var supplement = $('#vloga, #porocilo').data('supplement');
		
		$(`#removeFile`).modal('show');
		$(`#removeFileLabel span`).text(filename);
		
		modalConfirmFile(function(confirm){
			if(confirm){
				const recordId = $('#vloga, #porocilo').data('dataid');
				const parentFile = $(thisItem).parent('.fileuploaded');
				
				const fileDBId = $(parentFile).find('.priloge').attr('name');
				var link = '/evloge/vloga/'+recordId+'/delete/'+filename;
				link = link + '?fileId=' + fileDBId
				
				if(supplement){
					const $suppl = $(parentFile).find('.prilogeSupplement');
					const valSup = $suppl.val();
					const valSupName = $suppl.attr('name');
					
					if(!valSup){
						toasts.show({
							message:"Datoteka ni bila dodana kot dopolnitev!",
							color: 'danger',
							time: 10000
						});
						$("#removeFile [data-bs-dismiss=modal]").trigger('click');
						return;
					}
					link = link + '&supplId=' + valSupName
				}
				
				$.post(link, function(data){
					
					console.log(data);
					
					var jsonData = JSON.parse(data);
					if(!jsonData.error){
						const inputParent = $(parentFile).closest('.files_wrapper');
						const fieldname = $(inputParent).find('input[type="file"]').attr('id');
						
						parentFile.remove();
						$('#save').trigger('click');
						$('#savePorocilo').trigger('click');
						
						var fileSizeSum = parseFloat(0);
						$(inputParent).find(`.fileuploaded input[type="hidden"].${fieldname}FileSize`).each(function( index ) {
							fileSizeSum = fileSizeSum + parseFloat($( this ).val());
						});
						
						fileSizeSum = String(fileSizeSum.toFixed(2)).replace('.',',');
						
						const sizeSumSpan = $(inputParent).find('.sumFiles .size');
						sizeSumSpan.html(`${fileSizeSum} MB`);
						
					} else {
						toasts.show({
							message:jsonData.message,
							color: 'danger',
							time: 10000
						});
					}
				});
			}
			$("#removeFile [data-bs-dismiss=modal]").trigger('click');
		});
	})
	
	var modalConfirmFile = function(callback){
		$("#removeFileBtn").on("click", function(){
			callback(true);
		});
	}
}

function uploadFileButton(){
	$(document).on('click', '.uploadFileButton', function(e){
		e.preventDefault();
		e.stopPropagation();
		const fileUploadInputId = this.dataset.fileuploadinputid;
		const input = document.getElementById(fileUploadInputId);
		if(input){
			input.click();
		}
	});
}

$(function(){
	defineUpload();
	defineDeleteFile();
	uploadFileButton();
	
});
