
function allKeysEmpty(obj) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && obj[key] !== "") {
			return false;
		}
	}
	return true;
}

function getFormData($form){
	const data = new FormData($form[0]);
	const values = Object.fromEntries(data.entries());
	return values;
}

function isJsonString(str) {
	try {
		var json = JSON.parse(str);
		return (typeof json === 'object');
	} catch (e) {
		return false;
	}
}

function numberWithCommas(x) {
	return x.toLocaleString('sl-SI', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function parseDateWithTime(dateString) {
	// Split the string into its components (day, month, year, hour, minute)
	const parts = dateString.split(" ");
	const [day, month, year, time] = parts;
	
	// Split the time part into hour and minute
	const [hour, minute] = time.split(":");
	
	// Create a Date object with individual components
	return new Date(year, month - 1, day, hour, minute);
}

function parseNumber(element){
	let value = String($(element).val());
	if(value !== ''){
		if($(element).hasClass('whole')){
			value = value.replace(/[^0-9]*/g, ''); // remove all text, leave only numbers
			value = parseInt(value);
		}else{
			value = value.replace(/[^0-9,]*/g, ''); // remove all text, leave only numbers and commas
			value = value.replaceAll(',','.');
			value = parseFloat(value);
		}
	}
	if(isNaN(value)){
		value = 0;
	}
	return value;
}

module.exports = {
	allKeysEmpty,
	getFormData,
	isJsonString,
	numberWithCommas,
	parseDateWithTime,
	parseNumber,
 }